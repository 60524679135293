import { useEffect, useState } from 'react'
import { FeatureName } from 'src/utils/environment'
import { useQuery } from '@apollo/client'
import { getMeQuery } from 'src/graphql/queries'
import { useSubscribe } from './use-event-bus'

// ----------------------------------------------------------------------

/**
 * Get the state of a feature flag that is only applicable to the frontend.
 * @param featureName
 * @returns
 */
export function useLocalFeatureFlag(featureName: FeatureName) {
  const [featureEnabled, setFeatureEnabled] = useState(() => {
    const localStorageValue = localStorage.getItem(`feature:${featureName}`)
    return localStorageValue === 'true'
  })

  useSubscribe('feature:updated', ({ feature, enabled }) => {
    if (feature === featureName) {
      setFeatureEnabled(enabled)
    }
  })

  return featureEnabled
}

/**
 * Get a user specific feature flag that is stored in the backend.
 * @param featureName
 * @returns
 */
export function useFeatureFlag(featureName: FeatureName) {
  const { data } = useQuery(getMeQuery, { fetchPolicy: 'cache-first' })
  const [featureEnabled, setFeatureEnabled] = useState(() => {
    const localStorageValue = localStorage.getItem(`ambient_cached_features`)
    return localStorageValue?.split(',').includes(featureName) ?? false
  })

  useEffect(() => {
    if (data?.getMyEnabledFeatureFlags) {
      setFeatureEnabled(data.getMyEnabledFeatureFlags.includes(featureName))
      localStorage.setItem(`ambient_cached_features`, data.getMyEnabledFeatureFlags.join(','))
    }
  }, [data?.getMyEnabledFeatureFlags, featureEnabled, featureName])

  return featureEnabled
}
