// @mui
import Box from '@mui/material/Box'
import Link from '@mui/material/Link'
import Tooltip from '@mui/material/Tooltip'
import ListItemText, { ListItemTextProps } from '@mui/material/ListItemText'
// routes
import { RouterLink } from 'src/routes/components'
//
import { useCallback } from 'react'
import Iconify from '../../iconify'
//
import { NavItemProps, NavConfigProps } from '../types'
import { StyledItem, StyledIcon, StyledDotIcon } from './styles'

// ----------------------------------------------------------------------

type Props = NavItemProps & {
  config: NavConfigProps
}

export default function NavItem({
  item,
  open,
  depth,
  active,
  config,
  externalLink,
  onClick,
  clickableWithChildren,
  ...other
}: Props) {
  const { title, path, icon, info, children, disabled, caption, roles } = item

  const handleChevronClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      // make sure the event doesn't propagate to the parent because it will trigger the parent onClick
      event.stopPropagation()
      event.preventDefault()
      onClick?.(event)
    },
    [onClick]
  )

  const subItem = depth !== 1

  let primaryTypographyProps: ListItemTextProps['primaryTypographyProps'] = {
    noWrap: true,
    typography: 'body2',
    textTransform: 'capitalize',
    fontWeight: active ? 'fontWeightBold' : 'fontWeightMedium',
  }
  if (other.variant === 'small') {
    primaryTypographyProps = {
      noWrap: true,
      typography: 'caption',
      color: 'text.secondary',
      fontWeight: active ? 'fontWeightSemiBold' : 'fontWeightMedium',
    }
  }

  const renderClickable = (content: JSX.Element) => {
    if (clickableWithChildren && !!children) {
      return (
        <Link
          intercom-data-attribute={title.toLowerCase()}
          component={RouterLink}
          href={path}
          underline="none"
          color="inherit"
          sx={{
            ...(disabled && {
              cursor: 'default',
            }),
          }}
        >
          {content}
        </Link>
      )
    }

    return content
  }

  const renderContent = renderClickable(
    <StyledItem disableGutters disabled={disabled} active={active} depth={depth} config={config} {...other}>
      <>
        {icon && <StyledIcon size={config.iconSize}>{icon}</StyledIcon>}

        {subItem && (
          <StyledIcon size={3} sx={{ ml: 2 }}>
            <StyledDotIcon active={active} />
          </StyledIcon>
        )}
      </>

      {!(config.hiddenLabel && !subItem) && (
        <ListItemText
          primary={title}
          secondary={
            caption ? (
              <Tooltip title={caption} placement="top-start">
                <span>{caption}</span>
              </Tooltip>
            ) : null
          }
          primaryTypographyProps={primaryTypographyProps}
          secondaryTypographyProps={{
            noWrap: true,
            component: 'span',
            typography: 'caption',
            color: 'text.secondary',
          }}
        />
      )}

      {info && (
        <Box component="span" sx={{ ml: 1, lineHeight: 0 }}>
          {info}
        </Box>
      )}

      {children !== undefined && (
        <Iconify
          width={24}
          onClick={handleChevronClick}
          icon={open ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-forward-fill'}
          sx={{
            ml: 1,
            flexShrink: 0,
            p: 0.5,
            '&:hover': {
              backgroundColor: 'action.hover',
              borderRadius: 6,
            },
          }}
        />
      )}
    </StyledItem>
  )

  // Hidden item by role
  if (roles && !roles.includes(`${config.currentRole}`)) {
    return null
  }

  // External link
  if (externalLink)
    return (
      <Link
        intercom-data-attribute={title.toLowerCase()}
        href={path}
        target="_blank"
        rel="noopener"
        underline="none"
        color="inherit"
        sx={{
          ...(disabled && {
            cursor: 'default',
          }),
        }}
      >
        {renderContent}
      </Link>
    )

  // Has child
  if (children) {
    return renderContent
  }

  // Default
  return (
    <Link
      intercom-data-attribute={title.toLowerCase()}
      component={RouterLink}
      href={path}
      underline="none"
      color="inherit"
      sx={{
        ...(disabled && {
          cursor: 'default',
        }),
      }}
    >
      {renderContent}
    </Link>
  )
}

export function ViewAllNavItem({ item, open, depth, active, config, externalLink, ...other }: Props) {
  const { title, path, icon, info, children, disabled } = item

  const renderContent = (
    <StyledItem disableGutters disabled={disabled} active={active} depth={depth} config={config} {...other}>
      <>{icon && <StyledIcon size={config.iconSize}>{icon}</StyledIcon>}</>

      <ListItemText
        sx={{ ml: depth }}
        primary={
          <Tooltip title={title} placement="top-start">
            <span>{title}</span>
          </Tooltip>
        }
        primaryTypographyProps={{
          noWrap: true,
          component: 'span',
          typography: 'caption',
          color: 'text.secondary',
        }}
      />

      {info && (
        <Box component="span" sx={{ ml: 1, lineHeight: 0 }}>
          {info}
        </Box>
      )}

      {!!children && (
        <Iconify
          width={16}
          icon={open ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-forward-fill'}
          sx={{
            ml: 1,
            flexShrink: 0,
          }}
        />
      )}
    </StyledItem>
  )

  // Default
  return (
    <Link
      intercom-data-attribute={title.toLowerCase()}
      component={RouterLink}
      href={path}
      underline="none"
      color="inherit"
      sx={{
        ...(disabled && {
          cursor: 'default',
        }),
      }}
    >
      {renderContent}
    </Link>
  )
}
