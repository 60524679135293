import { useEffect } from 'react'
// @mui
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Drawer from '@mui/material/Drawer'
// hooks
import { useResponsive } from 'src/hooks/use-responsive'
// components
import { LogoFull } from 'src/components/logo'
import Scrollbar from 'src/components/scrollbar'
import { usePathname } from 'src/routes/hooks'
import { NavSectionVertical } from 'src/components/nav-section'
//
import { useDesktopApp } from 'src/hooks/use-desktop-app'
import { useSubscriptionStatus } from 'src/hooks/use-subscription-status'
import { useTheme } from '@mui/material'
import { NAV } from '../config-layout'
import { useNavData } from './config-navigation'
import { MeetingBotNavControls, NavToggleButton } from '../_common'
import FreeTrialEndedCard from '../_common/nav-widgets/free-trial-ended-card'

// ----------------------------------------------------------------------

type Props = {
  openNav: boolean
  onCloseNav: VoidFunction
}

export default function NavVertical({ openNav, onCloseNav }: Props) {
  const { shape, palette } = useTheme()
  const pathname = usePathname()
  const { desktopHeaderHeight, isDesktopApp } = useDesktopApp()

  const showVertNav = useResponsive('up', NAV.SHOW_VERT_NAVBAR_SIZE)

  const { subscribed } = useSubscriptionStatus()

  const navData = useNavData()

  useEffect(() => {
    if (openNav) {
      onCloseNav()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      {/* Desktop apps don't generally have a logo in the nav bar */}
      {!isDesktopApp && <LogoFull sx={{ mt: 3, ml: 4, mb: 1, width: 110 }} />}
      {isDesktopApp && <Box sx={{ height: 20 }} />}
      <NavSectionVertical
        data={navData}
        config={{
          iconSize: 20,
          itemRadius: shape.borderRadius,
          itemPadding: '0px 10px',
          itemRootHeight: 30,
          itemSubHeight: 30,
          itemGap: 2,
        }}
      />
      <Box sx={(theme) => ({ height: theme.spacing(3) })} />
      <Box mt={2} pb={2}>
        {!subscribed ? <FreeTrialEndedCard /> : <MeetingBotNavControls />}
      </Box>
    </Scrollbar>
  )

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { [NAV.SHOW_VERT_NAVBAR_SIZE]: 0 },
        width: { [NAV.SHOW_VERT_NAVBAR_SIZE]: NAV.W_VERTICAL },
        backgroundColor: palette.nav.background,
      }}
    >
      <NavToggleButton />

      {showVertNav ? (
        <Stack
          sx={{
            pt: `${desktopHeaderHeight / 2}px`,
            height: 1,
            position: 'fixed',
            width: NAV.W_VERTICAL,
            borderRight: `dashed 1px ${palette.nav.divider}`,
          }}
        >
          {renderContent}
        </Stack>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          PaperProps={{
            sx: {
              pt: `${desktopHeaderHeight / 2}px`,
              width: NAV.W_VERTICAL,
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  )
}
